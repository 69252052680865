.container {
    display: flex;
    column-gap: 20px;
    padding: 20px;
}

.fieldsSection {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 20px;
    justify-content: flex-end;
}

.button {
    align-self: end;
}

@media (max-width: 500px) {
    .container {
        flex-direction: column;
        row-gap: 20px;
    }
}