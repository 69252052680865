.container {
    display: flex;
    min-height: 42px;
    column-gap: 10px;
}

.pageBox {
    padding: 8px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clickable {
    cursor: pointer;
}

.rightMargin {
    margin-right: 16px;
}

.leftMargin {
    margin-left: 16px;
}

.horizontalMargin {
    margin-right: 16px;
    margin-left: 16px;
}

@media (max-width: 660px) {
    .container {
        column-gap: 2px;
    }

    .pageBox {
        padding: 2px;
        min-width: 35px;
    }

    .rightMargin {
        margin-right: 4px;
    }

    .leftMargin {
        margin-left: 4px;
    }

    .horizontalMargin {
        margin-right: 4px;
        margin-left: 4px;
    }
}