.dialogContent {
    position: relative;
}

.gameFrameWrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.gameFrame {
    height: 100%;
    /*height: calc(100% - 10px);*/
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    border: none;
    z-index: 1000;
}

.topBar {
    z-index: 9000;
    position: absolute;
    height: 40px;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
}